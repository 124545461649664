import { render } from "./DistrictCardList.vue?vue&type=template&id=31bf290e&scoped=true"
import script from "./DistrictCardList.vue?vue&type=script&setup=true&lang=js"
export * from "./DistrictCardList.vue?vue&type=script&setup=true&lang=js"

import "./DistrictCardList.vue?vue&type=style&index=0&id=31bf290e&lang=css"
import "./DistrictCardList.vue?vue&type=style&index=1&id=31bf290e&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-31bf290e"
/* hot reload */
if (module.hot) {
  script.__hmrId = "31bf290e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('31bf290e', script)) {
    api.reload('31bf290e', script)
  }
  
  module.hot.accept("./DistrictCardList.vue?vue&type=template&id=31bf290e&scoped=true", () => {
    api.rerender('31bf290e', render)
  })

}

script.__file = "src/views/index/components/DistrictCardList.vue"

export default script