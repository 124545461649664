<template>
  <div style="padding: 0 20px 20px 40px">
    <a-list
      :grid="{ gutter: 8, xs: 1, sm: 2, md: 2, lg: 3, xl: 3, xxl: 4 }"
      :data-source="dealerDistrictAbnormal"
      :pagination="pagination"
    >
      <template #renderItem="{ item }">
        <a-list-item>
          <a-card
            hoverable
            :title="getItemTitle(item)"
            :headStyle="{
              display: 'flex',
              'align-items': 'center',
              'text-align': 'center',
              'justify-content': 'center',
              'font-style': 'normal',
              'font-weight': '400',
              'font-size': '20px',
              'line-height': '20px',
            }"
            class="card-item"
            @click="districtCardClick(item)"
          >
            <a-row>
              <a-col :span="6"></a-col>
              <a-col :span="6" class="table-title center-title">
                {{ t('DealerDashboard.bloodPressure') }}
              </a-col>
              <a-col :span="6" class="table-title center-title">
                {{ t('DealerDashboard.bodyTemperature') }}
              </a-col>
              <a-col :span="6" class="table-title center-title">
                {{ t('DealerDashboard.BMI') }}
              </a-col>
            </a-row>

            <div class="line-title"></div>

            <a-row>
              <a-col :span="6" class="table-title">
                {{ t('DealerDashboard.MeasureCount') }}
              </a-col>
              <a-col :span="6" class="table-title center-title">
                {{ item.BloodPressureTotal }}
              </a-col>
              <a-col :span="6" class="table-title center-title">
                {{ item.TemperatureTotal }}
              </a-col>
              <a-col :span="6" class="table-title center-title">
                {{ item.BodyWeightTotal }}
              </a-col>
            </a-row>

            <div class="line-title"></div>

            <a-row>
              <a-col :span="6" class="table-title">
                {{ t('DealerDashboard.MeasureAbnormalCount') }}
              </a-col>
              <a-col :span="6" class="table-title red-title center-title">
                {{ item.BloodPressureAbnormal }}
              </a-col>
              <a-col :span="6" class="table-title red-title center-title">
                {{ item.TemperatureAbnormal }}
              </a-col>
              <a-col :span="6" class="table-title red-title center-title">
                {{ item.BodyWeightAbnormal }}
              </a-col>
            </a-row>

            <div class="line-title"></div>

            <a-row>
              <a-col :span="6" class="table-title">
                {{ t('DealerDashboard.MeasureAbnormalPercentage') }}
              </a-col>
              <a-col :span="6" class="table-title center-title">
                <span class="red-title">{{ bloodPresureRate(item) }}%</span>
              </a-col>
              <a-col :span="6" class="table-title center-title">
                <span class="red-title">{{ temperatureRate(item) }}%</span>
              </a-col>
              <a-col :span="6" class="table-title center-title">
                <span class="red-title">{{ bodyWeightTotalRate(item) }}%</span>
              </a-col>
            </a-row>
          </a-card>
        </a-list-item>
      </template>
    </a-list>
  </div>
</template>

<script setup>
  import { roundDecimal } from '@/utils/pocketKnives'
  import { ref, defineProps, defineEmits } from 'vue'
  import { useI18n } from 'vue-i18n'

  const { t } = useI18n()

  const emit = defineEmits(['clickCard'])
  const props = defineProps({
    showPagination: Boolean,
    dealerDistrictAbnormal: {
      type: Array,
      default() {
        return []
      },
    },
  })

  let pagination = false
  if (props.showPagination === true) {
    pagination = {
      onChange: (page) => {
        console.log(page)
      },
      pageSize: 9,
    }
  }

  // const districtData = [...Array(29)].map((_, i) => {
  //   return {
  //     key: i,
  //     district: `板橋區${i + 1}`,
  //     TemperatureTotal: Math.floor(Math.random() * 1000),
  //     TemperatureAbnormal: Math.floor(Math.random() * 10),
  //     BodyWeightTotal: Math.floor(Math.random() * 1000),
  //     BodyWeightAbnormal: Math.floor(Math.random() * 10),
  //     BloodPressureTotal: Math.floor(Math.random() * 1000),
  //     BloodPressureAbnormal: Math.floor(Math.random() * 10),
  //   }
  // })

  const bloodPresureRate = (item) => {
    const count = parseInt(item.BloodPressureAbnormal) | 0
    const total = parseInt(item.BloodPressureTotal) | 0
    if (total === 0) {
      return 0
    } else {
      return roundDecimal((count * 100) / total, 1)
    }
  }

  const temperatureRate = (item) => {
    const count = parseInt(item.TemperatureAbnormal) | 0
    const total = parseInt(item.TemperatureTotal) | 0
    if (total === 0) {
      return 0
    } else {
      return roundDecimal((count * 100) / total, 1)
    }
  }

  const bodyWeightTotalRate = (item) => {
    const count = parseInt(item.BodyWeightAbnormal) | 0
    const total = parseInt(item.BodyWeightTotal) | 0
    if (total === 0) {
      return 0
    } else {
      return roundDecimal((count * 100) / total, 1)
    }
  }
  const getItemTitle = (item) => {
    if (item.district) {
      return item.district
    }
    if (item.districtTownship) {
      return item.districtTownship
    }
    return ''
  }

  const districtCardClick = (item) => {
    if (item.district) {
      emit('clickCard', item.district)
    }
    if (item.districtTownship) {
      emit('clickCard', item.districtTownship)
      return item.districtTownship
    }
    emit('clickCard', '')
  }
</script>

<style>
  .ant-card-head-title {
    white-space: pre-wrap !important;
  }
</style>

<style lang="less" scoped>
  .card-item {
    background: #ffffff;
    border-radius: 10px;
    filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.2));

    .table-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* or 143% */

      color: #000000;
    }

    .center-title {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
    }

    .red-title {
      color: #ea6363;
    }
    .percent-title {
      font-weight: 400;
      font-size: 10px;
      line-height: 20px;

      color: #000000;
    }
    .line-title {
      margin-top: 5px;
      margin-bottom: 5px;
      border: 1px solid #f2f2f2;
    }
  }
</style>
